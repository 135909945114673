<template>
  <div class="new-clients-container">
    <admin-header-vue alias="" pageName="Update Company Size" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :value="newCompanySize.companySizeName"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Company Size Name" />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Update</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import Snackbar from '../../../../components/Snackbar'

/** This component contains the code to update an existing company size */
export default {
  name: 'updateCompanySize',
  components: {
    AdminHeaderVue,
    InputVue
  },
  data () {
    return {
      newCompanySize: {
        companySizeName: null,
        companySizeId: null
      },
      submitted: false,
      isValidName: false
    }
  },
  computed: {
    ...mapState('companies', ['companySizeDetails'])
  },
  watch: {
    companySizeDetails () {
      this.newCompanySize.companySizeName = this.companySizeDetails.company_size
    }
  },
  mounted () {
    this.newCompanySize.companySizeId = this.$route.params.id
    this.getSingleCompanySize(this.$route.params.id)
  },
  methods: {
    ...mapActions('companies', ['updateCompany', 'getSingleCompanySize']),
    /** This method defines the behavior for when the company size name (Input value) is changed
     * @param name {String} - The name of the new company size
     * @param isValid {Boolean}
     * @public
     */
    onChangeName (name, isValid) {
      this.newCompanySize.companySizeName = name
      this.isValidName = isValid
    },
    /** This method checks to see if any changes have been made to existing values
     * @public
     */
    checkChanges () {
      if (this.companySizeDetails.company_size !== this.newCompanySize.companySizeName) {
        return false
      }
      return true
    },
    /** This method submits the form and displays the success/failure message in the Snackbar
     * @public
     */
    handleSubmit (e) {
      this.submitted = true
      if (!this.checkChanges()) {
        if (!(this.isValidName)) {
          this.updateCompany(this.newCompanySize)
        }
      } else {
        Snackbar({ message: 'No Updates Found.', type: 'is-danger' })
      }
    },
    /** This method redirects the user back to the company size page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/company_size')
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
   margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .column {
        .upload {
          width: 200px;
          height: 60px;
          align-items: center;
          justify-content: center;
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          img {
            width: 200px;
            height: 60px;
            object-fit: contain;
            padding: 5px;
          }
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
